<template>
  <div>
    <v-card>
      <v-card-title class="align-start ">
        <span>Groups </span>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="dialog = true"
        >
          <v-icon>
            {{ icons.mdiPlusCircle }}
          </v-icon>
          <span class="ml-1">Add new </span>
        </v-btn>
      </v-card-title>
      <v-progress-linear
        :active="loading"
        indeterminate
        color="primary"
      ></v-progress-linear>
    </v-card>
    <v-spacer></v-spacer>
    <v-row class="mt-4">
      <v-col
        v-for="group in groups"
        :key="group.id"
        cols="12"
        sm="4"
      >
        <v-spacer></v-spacer>
        <v-card
          class="overflow-hidden"
          :to="`/groups/${group.id}`"
        >
          <v-card-title>{{ group.name }}</v-card-title>
          <v-card-text>
            {{ group.description }}
          </v-card-text>
          <v-card-text>
            <v-divider></v-divider>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <div class="me-auto pe-4">
              <p class="d-flex align-center mb-6">
                <v-icon color="primary">
                  {{ icons.mdiLockOpenOutline }}
                </v-icon>
                <span class="ms-3">{{ group.visibility }}</span>
              </p>
              <p class="d-flex align-center mb-0">
                <v-icon color="primary">
                  {{ icons.mdiShieldAccountVariant }}
                </v-icon>
                <span class="ms-3">{{ group.category.charAt(0).toUpperCase()
                  + group.category.slice(1) }}</span>
              </p>
            </div>

            <v-divider
              v-if="$vuetify.breakpoint.smAndUp"
              vertical
              inset
            >
            </v-divider>

            <div class="ms-auto ps-4">
              <p class="d-flex align-center mb-6">
                <v-icon color="primary">
                  {{ icons.mdiCalendarRange }}
                </v-icon>
                <span class="ms-3">{{ new Date(group.created_at).toLocaleDateString() }}</span>
              </p>
              <p class="d-flex align-center mb-0">
                <v-icon color="primary">
                  {{ icons.mdiTrendingUp }}
                </v-icon>
                <span class="ms-3">{{ group.status }}</span>
              </p>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!---Add group dialog-->
    <v-dialog
      v-model="dialog"
      persistent
      width="1024"
    >
      <validation-observer
        ref="observer"
        v-slot="{ invalid }"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Add a new group</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <error-response-card
                :show="show_error"
                :errors="errors"
              ></error-response-card>
              <v-row>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <v-text-field
                      v-model="group.name"
                      label="Group name*"
                      required
                      dense
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="description"
                    rules="required"
                  >
                    <v-textarea
                      v-model="group.description"
                      label="Group description *"
                      required
                      dense
                      rows="3"
                      :error-messages="errors"
                    ></v-textarea>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="category"
                    rules="required"
                  >
                    <v-select
                      v-model="group.category"
                      :items="[{ text: 'Savings', value: 'saving' }, { text: 'Investment', value: 'investment' }, { text: 'Retirement', value: 'retirement' }, { text: 'Others', value: 'others' }]"
                      label="Group category*"
                      required
                      dense
                      :error-messages="errors"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="invitation_policy"
                    rules="required"
                  >
                    <v-select
                      v-model="group.invitation_policy"
                      :items="[{ text: 'Only admin can invite', value: 'admin-only' }, { text: 'Any member can invite', value: 'any-member' }]"
                      label="Who can invite new members?*"
                      required
                      dense
                      :error-messages="errors"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="visibility"
                    rules="required"
                  >
                    <v-select
                      v-model="group.visibility"
                      :items="[{ text: 'Public', value: 'public' }, { text: 'Private', value: 'private' }]"
                      label="Group visibility?*"
                      required
                      dense
                      :error-messages="errors"
                    ></v-select>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              variant="text"
              @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
              color="primary"
              variant="text"
              :loading="loading"
              :disabled="invalid"
              @click="createSavingsGroup()"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </validation-observer>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiLockOpenOutline,
  mdiAccountOutline,
  mdiStarOutline,
  mdiTrendingUp,
  mdiPlusCircle,
  mdiCalendarRange,
  mdiShieldAccountVariant,
} from '@mdi/js'
import { required, numeric } from 'vee-validate/dist/rules'

// eslint-disable-next-line object-curly-newline
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import ErrorResponseCard from '@/components/ErrorResponseCard.vue'

setInteractionMode('eager')

extend('numeric', {
  ...numeric,
  message: '{_field_} should be a valid number',
})

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ErrorResponseCard,
  },
  setup() {
    return {
      color(status) {
        if (status === 'active') {
          return 'secondary'
        }
        if (status === 'gaveup') {
          return 'error'
        }

        return 'success'
      },
      icons: {
        mdiLockOpenOutline,
        mdiAccountOutline,
        mdiStarOutline,
        mdiTrendingUp,
        mdiPlusCircle,
        mdiCalendarRange,
        mdiShieldAccountVariant,
      },
    }
  },
  data() {
    return {
      show_error: false,
      dialog: false,
      groups: [],
      loading: true,
      search: '',
      errors: {},
      options: {},
      pagination: {},
      group: {},
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchApiData()
      },
    },
    deep: true,
  },
  created() {
    this.fetchApiData()
  },
  methods: {
    /**
     * Hits the api to save this savings group
     */
    createSavingsGroup() {
      this.loading = true
      this.$http
        .post('/groups', this.group)
        .then(() => {
          this.fetchApiData()
        })
        .finally(() => {
          this.loading = false
          this.dialog = false
        })
        .catch(err => {
          console.log(err)
        })
    },
    fetchApiData() {
      this.loading = true

      // const { page, itemsPerPage } = this.options
      const page = 0
      const itemsPerPage = 100
      this.$http
        .get(`/groups?page=${page}&per_page=${itemsPerPage}`)
        .then(res => {
          this.groups = res.data.data
          this.pagination = res.data.meta
        })
        .finally(() => {
          this.loading = false
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>
